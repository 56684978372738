.cardBook img{
    height: 60vh;

    cursor: pointer;
    border:2px solid white;
    position: relative;
    opacity: 80%;
    transition: 0.5s;
}
.cardBook h2{
    
    cursor: pointer;
}
.cardBook{

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
}

.cardBook img:hover{
    box-shadow: 0 0 20px 10px white;
    opacity: 100%;
    transition: 0.5s;
}

@media (max-width: 800px){
    .cardBook{
    margin-bottom:8vh;
    }
}