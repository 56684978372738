.article {
    margin: 5vw;

}

.article-book {
    display: flex;
    align-items: center;
}

.article-book-frontPage {
    margin-right: 5vh;
    height: 70vh;
    border: 2px solid white;
}

.article-book-shop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.article-book-shop img {
    height: 10vh;
    margin-right: 5vw;
    margin-top: 5vh;
    cursor: pointer;

}
.article-book-shop h3{
    margin-right: 3vh;
}

.article-book-pdf {
    height: 10vh;
    margin-right: 5vw;
    margin-top: 5vh;
    cursor: pointer;
}

.article-paragraph {
    margin-top: 10vh;
    text-align: justify;
}
.article-book p{
    text-align: justify;
   
  
}
@media (max-width:1100px){
    .article-book{
        display: flex;
      
         flex-direction: column;
      
    }
    .article{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .article-titleSynopBuy{
        display: flex;
        justify-self:center;
        align-items: center;
        flex-direction: column;
    }
    .article-book-frontPage{
        height: 40vh;
        margin:0;
    }
    @media (max-width:450px){
        .article-book-shop img{
            height: 7vh;
           
        }
    }
}