
.booksContainer{
    display: flex;
    justify-content: space-around;
    margin-top: 10vh;
}

.storiesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10vh 0vh;
  }

  .titles{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;

  }
.titles-lineBar{
    width: 30%;
    height: 0.3vh;
    background-color: aliceblue;
    
}

  .titles h2{
    font-size: 5vh;
    margin: 0vh 8vh;
    width: 19vw;
    display: flex;
    justify-content: center;
  }

@media (max-width: 800px){
.booksContainer{
display: flex;
flex-direction: column;

}
.storiesContainer{
  display: flex;
flex-direction: column;
}
}

