.navBar{
   
    background-image: linear-gradient(to top,
    black,
    rgb(16, 16, 95),
    rgb(102, 102, 181) ,
    rgb(16, 16, 95) 75%
    );
    
}
.navbar-nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.navbar-nav p{
    color: aliceblue;
     padding: 0.5vw 1vw;
     position: relative;
     transition: 0.5s;
    border-radius: 20px; 
    padding: 0.5vh 2vw
    
}

.navbar-nav p:hover{
     box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.8);  
    border-radius: 20px;  
    padding: 0.5vw 1vw;
    transition: 0.5s;
    background-color: black;
    padding: 0.5vh 2vw
}
.navBar-link{
    cursor: pointer;
}
.navbar-button{
  display: none;
 
  
}


@media (max-width:530px){
.navbar-nav{
    display: flex;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    overflow: hidden;
    transition: 0.8s;
}
.navbar-nav.isActive{
    opacity: 1;
    pointer-events: auto;
    max-height: 500px; /* Ajusta la altura máxima según tu contenido */
    transition: 2s;
   
}
.navbar-button{
    display: flex;
    padding: 2vh 0vh;
    padding-left: 5vw;
    border:none;
    background: none;
    width: 100%;
}
.navbar-button img{
    cursor: pointer;
    height: 4vh;
    
   

    
}


}