.cardStories img{
height: 25vh;
cursor: pointer;
opacity: 70%;
transition: 0.5s;
width: 25vh;
border-radius: 100%;

}
.cardStories-imageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    width: 25vh;
    overflow: hidden;
    border-radius: 100%;
    border:2px solid white;
    transition: 0.5s;
}

.cardStories h4{
    cursor: pointer;
    
}

@media (max-width: 800px){

}

.cardStories{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 0 0 calc(25.33% - 20px); 
    margin-bottom: 2vh;
    padding: 10px;
    
    
}

.cardStories img:hover{
transition:1s;
height: 35vh;
width: 35vh;
opacity: 100%;

}
.cardStories-imageContainer:hover{
    border: 2px solid black;
    box-shadow: 0 0 20px 10px white;
    transition: 0.5s;
}

