.backgroundImage{
    background-image: url('../Header/imgHeader/header.png');
    height: 40vh;
    background-repeat: no-repeat;
    padding-left: 5vw;
    padding-top: 5vh;
    display: flex;
    background-size: cover;
}

.backgroundImage h1{
    color: aliceblue;
   
}
.backgroundImage h3{
    color: aliceblue;
  
}

.header-CCG{
    height: 20vh;
    border-radius: 10px;
    border:3px solid;  

}
.header-name{
    margin-left: 3vw;
}
.header-media{
  
    display: flex;
    justify-content: end;
    margin-right: 5vw;
}
.header-media img{
    height: 5vh;
    cursor: pointer;
    border:2px solid white;
    border-radius: 100%;
}
.header-media-FB{
    margin-left: 1vw;
    
}
.header-media a{
    height: 5vh;
}
.header-media-XMedia{
    display: none;
    height: 5vh;
    cursor: pointer;
    border:2px solid white;
    border-radius: 100%;
}
.header-media-FBMedia{
    display: none;
    height: 5vh;
    cursor: pointer;
    border:2px solid white;
    border-radius: 100%;
}

@media (max-width: 500px){
    .header-media img{
        display: none;
    }
    .header-media-XMedia{
        display: flex;
    }
    .header-media-FBMedia{
        display: flex;
        margin-left: 2vw;
    }
.header-mediaResponsive{
    display:flex ;
}
}
@media (max-width: 420px){
.backgroundImage{
    display: flex;
    flex-direction: column;
    padding-bottom: 11vh;
   
}
.header-name{
    margin-left: 0vw;
    margin-top: 4.5vh;
}
}