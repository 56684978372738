.booksPublished{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.booksPublished p{
    margin: 6vh 0vh
}
@media (max-width:650px){
    .booksPublished img{
        height: 40vh;
    }
    .booksPublished{
        margin: 0vw 5vw;
    }
}