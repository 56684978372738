.about{
    display: flex;
    align-items: center;
    margin: 5vw;
}
.about img{
    height: 60vh;
    margin-right: 5vw;
    border:2px solid white;
}
.about p{
    text-align: justify;
}
@media (max-width:990px){
    .about{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

}
@media (max-width:540px){
.about img{
    height: 30vh;
}
}